<template>
  <div class="question column" :class="width">
    <div class="question-content card">
      <div class="card-content" v-html="decodeHtml(question.question[lang])"></div>
    </div>
  </div>
</template>

<script>
import { QuestionMixin } from '@/mixins/question'

export default {
  name: 'ScreenOut',
  mixins: [QuestionMixin],
  data(){
    return {
      width: 'is-8'
    }
  },
  async created() {
    await this.$emit("input", { [this.question.qid]: "Y" });
    await this.$emit("screen-out");
  }
}
</script>
